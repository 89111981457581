import React from 'react';

const SearchBar = ({address, onChange, isValid}) => {
  const style = {};
  if (isValid === false) {
    style.border = '1px solid red';
  } else if (isValid) {
    style.border = '1px solid green';
  }
  return (
    <input
      className="SearchBar"
      style={style}
      key="address"
      value={address}
      placeholder={"Search Bitcoin address (P2WSH, P2WPKH, P2PKH and P2SH)"}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

export default SearchBar;
