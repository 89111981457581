import blockchain from "../api/blockchain";

class BitcoinFlow {
  async buildFromAddress(address, currentBlock, currentData = [], onExpand, onHandleClick) {
    const initialPos = {
      x: 0, y: 0
    }
    const data = await blockchain.getTransactions(address);
    if (!data) return;
    const transactions = currentData.filter(el => el.type === 'transaction');
    const addresses = currentData.filter(el => el.type === 'address');
    const links = currentData.filter(el => !el.type);

    const add = (data, to) => {
      if (!to.find(e => e.id === data.id))
        to.push(data);
    }

    data.txs.forEach((transaction) => {
      const txColor = transaction.block_height + 6 <= currentBlock.height ? 'green' : transaction.block_height < currentBlock.height ? 'orange' : 'red';
      const foundTx = transactions.find(t => t.id === transaction.hash);
      const transactionNode = foundTx || {
        id: transaction.hash,
        type: 'transaction',
        data: { ...transaction, onHandleClick },
        position: initialPos,
        style: { borderColor: txColor }
      };
      if (!foundTx)
        transactions.push(transactionNode);

      transaction.out.forEach(out => {
        const found = addresses.find(a => a.id === out.addr)
        const f =  found || {
          id: out.addr,
          data: out.addr === address ? { ...data, isSelected: true } : { ...out, input: true },
          position: initialPos,
          sourcePosition: 'right',
          targetPosition: 'left',
          type: 'address',
        }
        f.data.onExpand = onExpand(out.addr);
        f.data.onHandleClick = onHandleClick;
        if (out.addr === address)
          f.data = { ...data, isSelected: true }
        add(f, addresses)
        add({ id: transaction.hash + out.addr, source: transaction.hash, target: out.addr, sourceHandle: out.addr, animated: true }, links)
      });

      transaction.inputs.forEach(input => {
        const found = addresses.find(a => a.id === input.prev_out.addr)
        const f =  found || {
          id: input.prev_out.addr,
          data: input.prev_out.addr === address ? { ...data, isSelected: true, } : { ...input.prev_out, input: false },
          position: initialPos,
          sourcePosition: 'right',
          targetPosition: 'left',
          type: 'address',
        }
        f.data.onExpand = onExpand(input.prev_out.addr);
        f.data.onHandleClick = onHandleClick;
        if (input.prev_out.addr === address)
          f.data = { ...data, isSelected: true }
        add(f, addresses);
        add({ id: input.prev_out.addr + transaction.hash , source: input.prev_out.addr, target: transaction.hash, targetHandle: input.prev_out.addr, animated: true }, links)
      });
    })

    return [...addresses, ...transactions, ...links];
  }
}

export default new BitcoinFlow();
