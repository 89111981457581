import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

export default memo(({ data, isConnectable }) => {
  const outputHandles = data.out.map((out, i) => (
    <>
      <Handle
        type="source"
        position="right"
        className="react-flow__node-handle_grow"
        id={out.addr}
        style={{ top: 50 + 15 * i , background: out.spent ? 'red' : 'green' }}
        isConnectable={isConnectable}
        onClick={() => data.onHandleClick(out.addr)}
      ><p className="react-flow__node-label_output" >{out.value / 100000000}</p></Handle>
    </>
  ))

  const inputHandles = data.inputs.map((input, i) => (
    <>
      <Handle
        type="target"
        position="left"
        className="react-flow__node-handle_grow"
        id={input.prev_out.addr}
        style={{ top: 50 + 15 * i , background: input.prev_out.spent ? 'red' : 'green' }}
        isConnectable={isConnectable}
        onClick={() => data.onHandleClick(input.prev_out.addr)}
      ><p className="react-flow__node-label_input" >{input.prev_out.value / 100000000}</p></Handle>
    </>
  ))

  const height = Math.max(data.out.length, data.inputs.length) * 15 + 50;
  return (
    <>
      <div style={{width: '100%', height: `${height > 90 ? height : 90}px`}}>
        { inputHandles }
        <div className="react-flow__node-transaction-header">
          <div>Transaction {data.hash}</div>
          <div className="react-flow__node-header-button"><button onClick={()=> window.open(`https://www.blockchain.com/btc/tx/${data.hash}`, "_blank")}><FontAwesomeIcon icon={faExternalLinkAlt} /></button> </div>
        </div>
        <div className="react-flow__node-transaction-body">
          {data.result / 100000000} BTC <br/>
          Time : {new Date(data.time * 1000).toLocaleString()} <br />
          Height : {data.block_height}
          <div style={{width: '100%'}}>
            { outputHandles }
          </div>
        </div>



      </div>

    </>
  );
});
