import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faPlus, faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

export default memo(({ data }) => {
  const getInfos = () => (
    <>
      <FontAwesomeIcon icon={faWallet} /> {data.final_balance / 100000000} <br/>
    </>
  )

  const getButton = () => (
    <>
      Add more info
      <div className="react-flow__node-body-button"><button onClick={data.onExpand}><FontAwesomeIcon icon={faPlus}/></button> </div>
    </>
  )

  const value = data.value / 100000000;
  const recieved = !isNaN(data.total_received) ? data.total_received / 100000000 : data.input ? value : null;
  const sent = !isNaN(data.total_sent) ? data.total_sent / 100000000 : !data.input ? value : null;

  return (
    <>
      <div style={{width: '100%'}}>
        <Handle
          type="source"
          position="right"
          isValidConnection={false}
          className="react-flow__node-handle_grow"
          style={{ background: '#555', top: '50px' }}
        ><p className="react-flow__node-label_output" >{sent}</p></Handle>
        <Handle
          type="target"
          isValidConnection={false}
          position="left"
          className="react-flow__node-handle_grow"
          style={{ background: '#555', top: '50px' }}
        ><p className="react-flow__node-label_input" >{recieved}</p></Handle>
        <div className="react-flow__node-address-header" style={data.isSelected ? {background: '#00800047'} : {}}>
          <div>Address {data.address || data.addr}</div>
          <div className="react-flow__node-header-button"><button onClick={()=> window.open(`https://www.blockchain.com/btc/address/${data.address || data.addr}`, "_blank")}><FontAwesomeIcon icon={faExternalLinkAlt} /></button> </div>
          <div className="react-flow__node-header-button red-button"><button><FontAwesomeIcon icon={faTimes} /></button> </div>
        </div>
        <div className="react-flow__node-address-body">
          { !isNaN(data.final_balance) ? getInfos() : getButton()}
        </div>
      </div>

    </>
  );
});
