import React, { useState, useEffect } from 'react';
import {useStoreState, useZoomPanHelper} from "react-flow-renderer";
import Flow from '../flow';
import flowService from '../../services/flow';
import blockChainService from '../../services/api/blockchain';
import '../../react-flow-theme.css';
import SearchBar from '../search/searchbar';
import qs from 'qs';
import { w3cwebsocket as W3CWebSocket } from "websocket";


import {validate} from 'bitcoin-address-validation';
import BlockData from "../data/block";
import ReactGA from "react-ga4";
const client = new W3CWebSocket('wss://ws.blockchain.info/inv');

function Board( ) {
  const [data, setData] = useState(null);
  const [address, setAddress] = useState('');
  const [focused, focusNode] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [currentBlock, setCurrentBlock] = useState({ hash: null});
  const [currentUnconfirmed, setCurrentUnconfirmed] = useState({ hash: ''});
  const nodes = useStoreState(state => state.nodes, (prev, next) => prev.length === next.length && prev[prev.length - 1]?.position?.x);
  const { setCenter } = useZoomPanHelper();

  const updateInput = async (input) => {
    setIsValid(validate(input));
    setAddress(input);
  }

  const onNewMessage = (message) => {
    const messageData = JSON.parse(message.data);

    switch (messageData.op) {
      case 'utx':
        setCurrentUnconfirmed(messageData.x)
        break;
      case 'block':
        setCurrentBlock(messageData.x);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    blockChainService.getLastBlock().then((res) => {
      setCurrentBlock(res)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    data && data.map(t => {
      if (t.type === 'transaction') {
        const newTxHeight = t.data.block_height;
        const currentHeight = currentBlock.height;
        const txColor = !newTxHeight ? 'red' : currentHeight >= newTxHeight + 6 ? 'green' : 'orange'
        t.style = { borderColor: txColor }
      }
      return t;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBlock, data])

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = qs.parse(windowUrl.replace('?', ''));
    if (params.address && validate(params.address)) {
      blockChainService.getLastBlock().then((res) => {
        setCurrentBlock(res)
        setAddress(params.address);
        setIsValid(true);
      });
    }
    client.onopen = () => {
      client.send('{ "op": "blocks_sub" }')
      client.send('{ "op": "unconfirmed_sub" }')
    };
    client.onmessage = onNewMessage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onExpand = (address) => () => {
    setAddress(address)
  }

  const onHandleClick = (target) => {
    focusNode(target)
  }

  const onClear = () => {
    setData(null)
    setAddress('')
  }

  useEffect(() => {
    console.log(focused, nodes)
    if (!focused) return;
    const rf = nodes.find(d => d.id === focused).__rf;

    setCenter(rf.position.x + rf.width / 2, rf.position.y)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  useEffect(() => {
    if (isValid && address !== '') {
      flowService.buildFromAddress(address, currentBlock, data || [], onExpand, onHandleClick).then((res) => {
        if (res) {
          ReactGA.send({ action: 'data loaded properly', category: 'error', data: {address}});
          setData(res)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, address]);

  return (
    <>
      { data && <Flow data={data} address={address} onClear={onClear} /> }
      <SearchBar address={address} onChange={updateInput} isValid={isValid}/>
      <BlockData block={currentBlock} unconfirmed={currentUnconfirmed}/>
    </>
  );
}

export default Board;
