import dagre from "dagre";
import {isNode} from "react-flow-renderer/nocss";


const layoutGraph = (graph, nodes) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: 'LR', edgesep: 10, ranksep: 500, nodesep: 50 });

  graph.forEach((el) => {
    if (isNode(el)) {
      const node = nodes.find(node => node.id === el.id)
      if (node.position.x === 0)
        dagreGraph.setNode(el.id, { width: node.__rf.width, height: node.__rf.height });
      else
        dagreGraph.setNode(el.id, { width: node.__rf.width, height: node.__rf.height, x: node.position.x, y: node.position.y });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  })

  dagre.layout(dagreGraph);

  return graph.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);

      el.position = {
        x: nodeWithPosition.x - nodeWithPosition.width / 2,
        y: nodeWithPosition.y - nodeWithPosition.height / 2,
      };
    }

    return el;
  });
}

export default layoutGraph;
