import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga4";

class Blockchain {
  async getTransactions(address) {
    let result;
    try {
      result = await axios.get(`https://blockchain.info/rawaddr/${address}?cors=true`)
    } catch (e) {
      toast.error('Whoopsie ! A network error happened, we probably are rate limited...', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    }
    if (!result?.data?.txs?.length) {
      toast.info('It seems this address has no transactions', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    }
    ReactGA.send({ action: 'getAddress failed', category: 'error'});
    return result?.data;
  }

  async getLastBlock() {
    let result;
    try {
      result = await axios.get(`https://api.txflow.io/latestblock`)
    } catch (e) {
      toast.error('Whoopsie ! A network error happened, we probably are rate limited...', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
      ReactGA.send({ action: 'getLastBlock failed', category: 'error'});
    }

    return result?.data;
  }
}

export default new Blockchain();
