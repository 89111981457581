import React from 'react';

function BlockData({ block, unconfirmed }) {
  return (
    <div className="BlockData">
      <p>Current block: {block?.hash}</p>
      <p>Height : {block?.height} ({new Date(block?.time * 1000).toLocaleString()})</p>
      <p>Unconfirmed tx: {unconfirmed?.hash}</p>
    </div>

  );
}

export default BlockData;
