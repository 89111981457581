import Board from './components/board';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ReactFlowProvider} from "react-flow-renderer";
import React from "react";
function App() {
  return (
    <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <ReactFlowProvider>
        <Board />
      </ReactFlowProvider>
    </div>
  );
}

export default App;
