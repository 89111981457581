import React from 'react';
import ReactFlow, {Controls, MiniMap, useStoreState, ControlButton, useZoomPanHelper } from 'react-flow-renderer';
import transaction from '../nodes/transaction';
import address from '../nodes/address';
import layoutGraph from '../../services/flow/graph';
import {faDotCircle, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const nodeTypes = {
  transaction, address
};

const Flow = ({ onClear, ...props  }) => {
  const nodeCount = props.data.filter(d => d.type).length;
  const nodes = useStoreState(state => state.nodes, (prev, next) => prev.length === next.length && prev[prev.length - 1]?.position?.x)

  const elements = nodeCount === nodes.length ? layoutGraph(props.data, nodes) : props.data;
  const { setCenter } = useZoomPanHelper();

  return (
  <div style={{ height: '100vh', width: '100%' }}>
      <ReactFlow elements={elements} nodeTypes={nodeTypes}>
      <MiniMap
        nodeStrokeWidth={3}
      />
        <Controls >
          <ControlButton title="Focus on the selected node" onClick={() => { const { __rf } = nodes.find((node) => node.id === props.address); setCenter(__rf.position.x + __rf.width / 2, __rf.position.y); console.log(__rf.position.x + __rf.width / 2, __rf.position.y) }}>
            <FontAwesomeIcon icon={faDotCircle} color="white"/>
          </ControlButton>
          <ControlButton title="Clear the scene" onClick={() => onClear()}>
            <FontAwesomeIcon icon={faTrashAlt} color="white"/>
          </ControlButton>
        </Controls>
      </ReactFlow>
  </div>
)};

export default Flow;
